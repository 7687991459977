import React, { Fragment, useEffect,useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Redirect, Switch, Route, Link } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import Elements from "../components/Elements";
import Profile from "../components/profile";
import { useHistory } from "react-router-dom";
import NavBar from "../components/NavBar";
import NavMenu from "../components/NavMenu";
import Schedule from "../components/Schedule";
import Grades from "../components/Grades";
import Attestation from "../components/Attestation";
import AmoPopUp from "../components/AmoPopUp";
import Convocation from "../components/convocation";
import CurrentResults from "../components/results_v2";
import Abs_justification from "../components/abs_justification";


export default function PortalPage() {
  const isLogged = useSelector((state) => state.user.isLogged);
  const elements = useSelector((state) => state.user.elements);
  const profile = useSelector((state) => state.user.profile);
  const email = useSelector((state) => state.user.email);
  const semesters = useSelector((state) => state.user.semesters);
  const grades = useSelector((state) => state.user.grades);
  const diploma = useSelector((state) => state.user.diploma);
  const [toggle, setToggle] = useState(true);
  const [amoPopUpSeen, setAmoPopUpSeen] = useState(true);

  const history = useHistory();
  useEffect(() => {
    // if (isError) {
    //   toast.error(errorMessage);
    //   dispatch(clearState());
    // }

    if (!isLogged) {
      //   dispatch(clearState());
      history.push("/login");
      return (
        <BrowserRouter>
          <Redirect to="/login" />
        </BrowserRouter>
      );
    }
  }, [isLogged]);
  return (
    <div className="portal" style={{ 
      display: 'flex', 
      flexDirection: 'column',
      minHeight: '100vh',
      width: '100%'
    }}>
      {amoPopUpSeen ? <AmoPopUp toggle={setAmoPopUpSeen} /> : null}
      <NavMenu toggle={toggle} setToggle={setToggle} email={email}/>
      <div style={{
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      }}>
        <NavBar toggle={toggle} setToggle={setToggle}/>
        <div style={{
          padding: '20px',
          flex: 1,
          overflow: 'auto',
          width: '100%',
          maxWidth: '100%'
        }}>
          <Switch>
            <Route
              path="/profile"
              component={() => <Profile profile={profile} email={email} />}
            />
            <Route
              path="/subjects"
              component={() => <Elements elements={elements} />}
            />
            <Route
              path="/timetable"
              component={() => (
                <Schedule diploma ={diploma} semesters={semesters} elements={elements} />
              )}
            />
            <Route
              path="/grades"
              component={() => <Grades grades={grades} />}
            />
			<Route
              path="/result-current"
              component={() => <CurrentResults profile={profile} />}
            />
            <Route
                path="/demandes"
                component={() => <Attestation diploma ={diploma} profile={profile} />}
            />
            <Route
                path="/convocation"
                component={() => <Convocation profile={profile} />}
            />
            <Route
                path="/abs_justification"
                component={() => <Abs_justification profile={profile} />}
            />
          </Switch>
          {/* <Profile profile={profile} />
        <Elements elements={elements} />
        <Schedule diploma ={diploma} elements={elements}/> */}
        </div>
      </div>
    </div>
  );
}
