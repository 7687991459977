import React, {useEffect, useState} from "react";
import "../styles/convocation.css";
import "../styles/results.css";

import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

export default function CurrentResults(props) {

    const [loading, setLoading] = useState(false);
    const [printing, setPrinting] = useState(false);
    const [exams, setExams] = useState(null);
    const [elps, setElps] = useState(null);
    const [metaData, setMetaData] = useState(null);

    let area_to_print = React.createRef();


    function gfg_Run(_date) {
        if(_date.toJSON() === null) return "";

        let date = _date.toJSON().slice(0, 10);
        return date.slice(8, 10) + '/'
            + date.slice(5, 7) + '/'
            + date.slice(0, 4);
    }

    async function fetchConvocation(apogee) {
        setLoading(true);
        try {
            let dateObject = new Date(props.profile.et_birthday);
            const req = {
                'apo': props.profile.id,
                'dn': gfg_Run(dateObject),
                'cin': props.profile.et_cin,
                'type': "note",
                'session': "rat",
            }

            console.log(props.profile);

            const apiUrl = "https://api.flshbm.ma/v1/exames-controle/student/exames";
            //const apiUrl = "http://system-api.test/v1/exames-controle/student/exames";

            let myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("origin", "https://api.flshbm.ma");
            //myHeaders.append("origin", "http://system-api.test");

            let formData = new FormData();
            formData.append("apo", req.apo);
            formData.append("dn", req.dn);
            formData.append("cin", req.cin);
            formData.append("type", req.type);
            formData.append("session", req.session);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formData,
                redirect: "follow",
            };

            const response = await fetch(
                apiUrl,
                requestOptions
            );

            let data = await response.json();
            console.log(data.data);
            setLoading(false);
            let exams = data.data[0];
            console.log(exams);
            
            if (exams && Array.isArray(exams)) {
                exams.sort(function(a, b) {
                    const date1 = new Date(
                        a.date_exam.split("/")[2],
                        a.date_exam.split("/")[1] - 1,
                        a.date_exam.split("/")[0]
                    );
                    const date2 = new Date(
                        b.date_exam.split("/")[2],
                        b.date_exam.split("/")[1] - 1,
                        b.date_exam.split("/")[0]
                    );
                    return date1 - date2;
                });
            }

            setExams(exams);
            setElps(data.data[3]);
            setMetaData(data.data[1]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }

    function getModuleCode(elp_code) {
        let elp_abv = null;
        if(elps) {
            // Get Filiers
            const Filiers = Object.keys(elps)
            Filiers.map(filier => {
                // Get Semesters
                const semesters = Object.keys(elps[filier][filier]);

                semesters.map(semester => {
                    const modules = Object.keys(elps[filier][filier][semester]);
                    modules.map(module => {
                        const elp = elps[filier][filier][semester][module];
                        if(elp === elp_code)
                            elp_abv = module;
                    })
                })
            })
        }
        return elp_abv;
    }

    async function printConvocation() {
        setPrinting(true);
        const areaToPrint = document.getElementById('area_to_print');

        setTimeout(() => {
            document.getElementById('viewport').setAttribute('content', 'width=1200px');

            areaToPrint.getElementsByClassName('exames_list')[0].classList.add('js_start_print');

            const html2canvasOptions = {
                allowTaint: true,
                removeContainer: true,
                backgroundColor: null,
                imageTimeout: 15000,
                logging: true,
                scale: 2,
                useCORS: true,
            };

            html2canvas(areaToPrint, html2canvasOptions).then((canvas) => {
                // Few necessary setting options
                const contentDataURL = canvas.toDataURL('image/png');
                const imgWidth = 210;
                const pageHeight = 295;
                const imgHeight = canvas.height * imgWidth / canvas.width;
                const heightLeft = imgHeight;
                const pdf = new jsPDF('p', 'mm', 'a4', 1); // A4 size page of PDF
                const position = 0;

                pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
                pdf.save('Convocation-EXAMEN__' + props.profile.id); // Generated PDF

                document.getElementById('viewport').setAttribute('content', 'width=device-width, initial-scale=1');
                areaToPrint.getElementsByClassName('exames_list')[0].classList.remove('js_start_print');

                setPrinting(false);
            });

        }, 500)
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchConvocation(props.profile.id);
        };
        fetchData();
    }, []);

    return (
        <div className="ui container results-container">
            {loading ? (
                <div className="ui active dimmer">
                    <div className="ui large text loader">جاري التحميل...</div>
                </div>
            ) : (
                <div className="ui raised segments">
                    <div className="ui center aligned secondary segment">
                        <h2 className="ui header animated fadeIn">
                            Résultats Provisoires - Session Ordinaire Automne
                            <div className="sub header" style={{marginTop: '10px'}}>
                                النتائج المؤقتة - إمتحانات الدورة الخريفية العادية
                            </div>
                        </h2>
                    </div>
                    

                    <div id="area_to_print" ref={area_to_print}>
                        <div className="exames_list">
                            <div className="ui right aligned segment desktop-only"> 
                                <button className="ui basic button print-button" onClick={printConvocation}> 
                                    <i className="print icon"></i> 
                                    Imprimer / طباعة
                                </button>
                            </div>

                            <div className="ui segment">
                                <div className="ui stackable grid">
                                    <div className="eight wide column">
                                        <div className="ui list">
                                            <div className="item student-info-item">
                                                <div className="header">N° Apogée / رقم التسجيل</div>
                                                <div className="description">{props.profile.id}</div>
                                            </div>
                                            <div className="item student-info-item">
                                                <div className="header">Nom et Prénom / الإسم الكامل</div>
                                                <div className="description">{props.profile.et_l_name_fr} {props.profile.et_f_name_fr}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="eight wide column">
                                        <div className="ui list">
                                            <div className="item">
                                                <div className="header">Filière / المسلك</div>
                                                <div className="description">
                                                    {(exams && exams.length > 0) && exams[0].filier_name}
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="header">Année Universitaire / السنة الجامعية</div>
                                                <div className="description">2024-2025</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ui segment" style={{overflowX: 'auto'}}>
                                <div className="results-view">
                                    {/* العنوان للشاشات الكبيرة */}
                                    <table className="ui celled structured table results-table desktop-only">
                                        <thead>
                                            <tr>
                                                <th>Semestre<br/>الفصل</th>
                                                <th>Code Module<br/>رمز الوحدة</th>
                                                <th>Intitulé Module<br/>عنوان الوحدة</th>
                                                <th>Note<br/>النقطة</th>
                                                <th>Résultat<br/>النتيجة</th>
                                            </tr>
                                        </thead>
                                        {exams && (
                                            <tbody>
                                                {exams.map((exam, index) => (
                                                    <tr key={exam.id} className={exam.note_result === 'V' ? 'positive' : ''}>
                                                        <td>{exam.semestre}</td>
                                                        <td>{getModuleCode(exam.module_num)}</td>
                                                        <td>{exam.module_name}</td>
                                                        <td style={{textAlign: 'center'}}>
                                                            {exam.prof_note}
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <span className={`ui ${
                                                                exam.note_result?.toUpperCase() === 'V' ? 'green' :
                                                                exam.note_result === 'RAT' ? 'yellow' :
                                                                exam.note_result === 'AJ' ? 'red' :
                                                                exam.note_result === 'ABJ' ? 'blue' :
                                                                exam.note_result === 'ABI' ? 'black' :
                                                                'grey'
                                                            } tiny label`}>
                                                                {exam.note_result?.toUpperCase() === 'V' ? 'Validé' :
                                                                 exam.note_result?.toUpperCase() === 'NV' ? 'Non Validé' :
                                                                 exam.note_result?.toUpperCase() === 'RAT' ? 'Rattrapage' :
                                                                 exam.note_result?.toUpperCase() === 'AJ' ? 'Ajourné' :
                                                                 exam.note_result?.toUpperCase() === 'ABI' ? 'Absence Injustifiée' :
                                                                 exam.note_result?.toUpperCase() === 'ABJ' ? 'Absence Justifiée' :
                                                                 ''}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        )}
                                    </table>

                                    {/* عرض البطاقات للأجهزة المحمولة */}
                                    <div className="mobile-cards">
                                        {exams && exams.map((exam, index) => (
                                            <div key={exam.id} className={`ui fluid card ${exam.note_result === 'V' ? 'positive' : ''}`}>
                                                <div className="content">
                                                    <div className="header">
                                                        {exam.semestre}
                                                    </div>
                                                    <div className="meta">
                                                        {getModuleCode(exam.module_num)}
                                                    </div>
                                                    <div className="description">
                                                        <p>{exam.module_name}</p>
                                                        <div className="ui two statistics mini">
                                                            <div className="statistic">
                                                                <div className="label">النقطة<br/>Note</div>
                                                                <div className="value">{exam.prof_note}</div>
                                                            </div>
                                                            <div className="statistic">
                                                                <div className="label">النتيجة<br/>Résultat</div>
                                                                <div className="value">
                                                                    <span className={`ui ${
                                                                        exam.note_result?.toUpperCase() === 'V' ? 'green' :
                                                                        exam.note_result === 'RAT' ? 'yellow' :
                                                                        exam.note_result === 'AJ' ? 'red' :
                                                                        exam.note_result === 'ABJ' ? 'blue' :
                                                                        exam.note_result === 'ABI' ? 'black' :
                                                                        'grey'
                                                                    } tiny label`}>
                                                                        {exam.note_result?.toUpperCase() === 'V' ? 'Validé' :
                                                                         exam.note_result?.toUpperCase() === 'NV' ? 'Non Validé' :
                                                                         exam.note_result?.toUpperCase() === 'RAT' ? 'Rattrapage' :
                                                                         exam.note_result?.toUpperCase() === 'AJ' ? 'Ajourné' :
                                                                         exam.note_result?.toUpperCase() === 'ABI' ? 'Absence Injustifiée' :
                                                                         exam.note_result?.toUpperCase() === 'ABJ' ? 'Absence Justifiée' :
                                                                         ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="ui segment">
                                <p style={{fontSize: '0.9em', color: '#666'}}>
                                    Note: Ces résultats sont provisoires et peuvent faire l'objet de modifications.
                                    <br/>
                                    ملاحظة: هذه النتائج مؤقتة وقابلة للتعديل.
                                    <br/>

                                    Aucune demande de correction de note ne sera acceptée par le coordinateur de la filière 48 heures après leur publication.
                                    <br/>
                                    لا يسمح بتقديم طلب تصحيح النقطة الى منسق المسلك 48 ساعة بعد إشهارها
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
