import React, { Component } from "react";
import {Button, Icon} from "semantic-ui-react";
import "./AmoPopUp.css";
export default class AmoPopUp extends Component {
    handleClick = () => {
        this.props.toggle();
    };
    render() {
        return (
            <div className="modal">
                <div className="modal_content">
                    <div className="modal_header">
                        <h1>تقدم بطلب للحصول على التأمين الصحي الأجباري الخاص بالطلبة</h1>
                        <button className="close_button" onClick={this.handleClick}>
                            <Icon name="close" />
                        </button>
                    </div>

                    <div className="presentation">
                        
                        <div className="presentation_ar">
                            <p>مرحبا بكم</p>
                            <p>من أجل التسهيل للتقدم بطلب للحصول على التأمين الصحي الأجباري الخاص بالطلبة، جامعة السلطان مولاي سليمان بني ملال قامت بوضع رهن إشارتكم هذه الإستمارة الإلكترونية.</p>
                            <p>يرجى ملء البيانات بعناية وعدم نسيان طباعتها، بمجرد الانتهاء منها، ووضغها بالمصلحة المعنية بمؤسستكم.</p>
                            <p>للمزيد من المعلومات، شاهدوا الفيديو :</p>
                        </div>
                    </div>

                    <div className="video_container">
                        <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/DC5J7mBku-0"
                            title="شرح التأمين الصحي"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>

                    <Button
                        color="violet"
                        animated
                        className="action_button"
                        style={{ marginTop: "20px" }}
                    >
                        <a href="http://www.usms.ac.ma/apps/amo/" target="_blank" rel="noopener noreferrer">
                            <Button.Content visible>تقديم الطلب</Button.Content>
                            <Button.Content hidden>
                                <Icon name="arrow right" />
                            </Button.Content>
                        </a>
                    </Button>
                </div>
            </div>
        );
    }
}
