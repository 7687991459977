import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import {Provider} from 'react-redux';
import 'semantic-ui-css/semantic.min.css';
import MetaTags from 'react-meta-tags';

ReactDOM.render(

  <Provider store={store}>
    <MetaTags>
        <meta name="viewport" id="viewport" content="width=device-width, initial-scale=1"/>
        <meta name="google" content="notranslate" />
        <meta httpEquiv="Content-Language" content="fr" />
    </MetaTags>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
