import React, {useEffect, useState} from "react";
import "../styles/convocation.css";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {Formik} from 'formik';
import {Form, Input, SubmitButton, ResetButton, TextArea, Field} from 'formik-semantic-ui-react';
import {Button, Icon, Label} from "semantic-ui-react";
import '@fontsource/cairo';
import '@fontsource/tajawal';
import '@fontsource/almarai';
import '@fontsource/poppins';
import '@fontsource/montserrat';
import '@fontsource/roboto';

export default function Abs_justification(props) {

    const [loading, setLoading] = useState(false);
    const [printing, setPrinting] = useState(false);
    const [exams, setExams] = useState(null);
    const [elps, setElps] = useState(null);
    const [metaData, setMetaData] = useState(null);

    let area_to_print = React.createRef();

    function gfg_Run(_date) {
        if (_date.toJSON() === null) return "";

        let date = _date.toJSON().slice(0, 10);
        return date.slice(8, 10) + '/'
            + date.slice(5, 7) + '/'
            + date.slice(0, 4);
    }

    async function fetchConvocation(id) {
        setLoading(true);
        let dateObject = new Date(props.profile.et_birthday);
        const req = {
            'apo': id,
            'dn': gfg_Run(dateObject),
            'cin': props.profile.et_cin,
            'type': "conv",
            'session': "rat",
        }

        console.log(props.profile);

        const apiUrl = "https://api.flshbm.ma/v1/exames-controle/student/exames";

        let myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("origin", "https://api.flshbm.ma");

        let formData = new FormData();
        formData.append("apo", req.apo);
        formData.append("dn", req.dn);
        formData.append("cin", req.cin);
        formData.append("type", req.type);
        formData.append("session", req.session);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
            redirect: "follow",
        };

        const response = await fetch(
            apiUrl,
            requestOptions
        );

        let data = await response.json();
        console.log(data.data);
        setLoading(false);
        let exams = data.data[0];
        console.log(exams);
        exams.sort(function (a, b) {
            const day1 = a.date_exam.split("/")[0];
            const day2 = b.date_exam.split("/")[0];
            /*const c = new Date( Date.parse(a.date_exam) )
            const d = new Date( Date.parse(b.date_exam) )
            console.log(c)*/
            return day1 - day2;
        });

        setExams(exams)
        setElps(data.data[3]);
        setMetaData(data.data[1]);
    }

    function getModuleCode(elp_code) {
        let elp_abv = null;
        if (elps) {
            // Get Filiers
            const Filiers = Object.keys(elps)
            Filiers.map(filier => {
                // Get Semesters
                const semesters = Object.keys(elps[filier][filier]);

                semesters.map(semester => {
                    const modules = Object.keys(elps[filier][filier][semester]);
                    modules.map(module => {
                        const elp = elps[filier][filier][semester][module];
                        if (elp === elp_code)
                            elp_abv = module;
                    })
                })
            })
        }
        return elp_abv;
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchConvocation(props.profile.id);
        };
        fetchData();
    }, []);

    const CustomButton = React.forwardRef(({open, ...props}, ref) => (
        <button className="myButton" ref={ref} {...props}>
            <Icon name="upload" /> إرسال التبرير
        </button>
    ));

    const UploadJustification = ({close, exam, onSuccess, onError}) => (
        <div className={"MyNewModal-overlay active"}>
            <div className={"MyNewModal active"}>
                <a className="close-modal" onClick={close}>
                    <svg viewBox="0 0 20 20">
                        <path fill="#000000"
                              d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"/>
                    </svg>
                </a>

                <div className={"modal-content"}>
                    <h3>تبرير غياب الوحدة : {exam.module_name}</h3>
                    <Formik
                        initialValues={{message: '', file: null}}
                        onSubmit={async (values, {setSubmitting}) => {
                            setLoading(true)
                            setSubmitting(true)
                            const apiUrl = "https://api.flshbm.ma/v1/exames-controle/abs-just";
                            console.log("Test")

                            let myHeaders = new Headers();
                            myHeaders.append("Accept", "application/json");
                            //myHeaders.append("Content-Type", "multipart/form-data");
                            myHeaders.append("origin", "https://api.flshbm.ma");

                            let dateObject = new Date(props.profile.et_birthday);
                            const req = {
                                'apo': props.profile.id,
                                'dn': gfg_Run(dateObject),
                                'cin': props.profile.et_cin,
                                'type': "conv",
                                'session': "rat",
                            }

                            const formData = new FormData();

                            formData.append("apo", req.apo);
                            formData.append("dn", req.dn);
                            formData.append("cin", req.cin);
                            formData.append('exam_id', exam.id);
                            formData.append('message', values.message);
                            formData.append('file_just', values.file);

                            console.log(formData);

                            const requestOptions = {
                                method: "POST",
                                headers: myHeaders,
                                body: formData,
                                redirect: "follow",
                            };

                            fetch(
                                apiUrl,
                                requestOptions
                            ).then((res) => res.json())
                                .then((data) => {
                                    setSubmitting(false)
                                    setLoading(false);
                                    console.log(data);
                                    onSuccess(data.data)
                                    setTimeout(() => {
                                        close()
                                    }, 500)
                                })
                                .catch((err) => {
                                    setSubmitting(false)
                                    setLoading(false);
                                    console.error(err);
                                    onError(exam)
                                    setTimeout(() => {
                                        close()
                                    }, 500)
                                });

                        }}
                    >
                        {({isSubmitting}) => (
                            <Form className={"form_abs"}>
								{/* Acceptance Checkbox */}
								<div className={"form-group"}>
									<Field name="acceptTerms">
										{({ field, form }) => (
											<div className="acceptance-checkbox">
												<input
													type="checkbox"
													id="acceptTerms"
													{...field}
													required
												/>
												<label htmlFor="acceptTerms">
													أوافق على <a href="/terms" target="_blank">الشروط والأحكام</a>
												</label>
											</div>
										)}
									</Field>
								</div>

								{/* Message Field */}
								<div className={"form-group"}>
									<Label className="required">نص عذر الغياب ؟</Label>
									<Field name="message">
										{({ form, field }) => {
											const { setFieldValue } = form;
											return (
												<textarea
													className="form-control"
													required
													rows={4}
													onChange={(e) => {
														setFieldValue("message", e.target.value);
													}}
												/>
											);
										}}
									</Field>
								</div>

								{/* File Upload */}
								<div className={"form-group"}>
									<Label className="required">مبرر الغياب</Label>
									<Field name="file">
										{({ form, field }) => {
											const { setFieldValue } = form;
											return (
												<input
													type="file"
													className="form-control"
													required
													onChange={(e) => {
														const file = e.target.files[0];
														// Check the size of the file
														if (file?.size / 1024 / 1024 < 2) {
															setFieldValue("file", file);
															console.log(file);
														}
													}}
												/>
											);
										}}
									</Field>
								</div>

								{/* Submit Button */}
								<SubmitButton className={"send_btn"} type="submit" disabled={isSubmitting}>
									إرسال تبرير الغياب
								</SubmitButton>
							</Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );

    async function examChange(_exam) {
        await fetchConvocation(props.profile.id)
        //console.log(exams)
        //setExams({exams, ...exams[index].message = _exam.message})
        //console.log(exams)

    }

    return (
        <div className="container">
            {loading && (
                <div className="loading-overlay">
                    <div className="ui active centered inline loader"/>
                </div>
            )}

            <div className="content-wrapper">
                <h1 className="head1">تبرير الغياب</h1>

                <div id="area_to_print" ref={area_to_print}>
                    <div className="exames_list">
                        <div className="user_info">
                            <div className="exames_list--item">
                                <p className="item-title">
                                    <Icon name="id card" /> رقم التسجيل: 
                                    <span>{props.profile.id}</span>
                                </p>
                                <p className="item-title">
                                    <Icon name="user" /> الاسم الكامل:
                                    <span>{props.profile.et_l_name_fr} {props.profile.et_f_name_fr}</span>
                                </p>
                            </div>
                        </div>

                        {exams && exams.length > 0 ? (
                            <div className="table-responsive">
                                <table className="examesTable">
                                    <thead>
                                    <tr>
                                        <th scope="col">تاريخ الامتحان</th>
                                        <th scope="col">ساعة الامتحان</th>
                                        <th scope="col">الفصل</th>
                                        <th scope="col">رقم الوحدة</th>
                                        <th scope="col">عنوان الوحدة</th>
                                        <th scope="col">تبرير غياب الوحدة</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {exams.map((exam, index) => (
                                            <tr key={exam.id}>
                                                <td>{exam.date_exam}</td>
                                                <td>{exam.time_exam}</td>
                                                <td>{exam.semestre}</td>
                                                <td>{getModuleCode(exam.module_num)}</td>
                                                <td>{exam.module_name}</td>
                                                <td>
                                                    {exam.message ? (
                                                        <a 
                                                            href={`https://api.flshbm.ma/v1/exames-controle/abs-just/download/${exam.key}/${exam.module_name}.pdf`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="download-link"
                                                        >
                                                            <button className="myButton success">
                                                                <Icon name="check" /> تم إرسال التبرير
                                                            </button>
                                                        </a>
                                                    ) : (
                                                        <Popup
                                                            className="newModal"
                                                            trigger={open => <CustomButton open={open}/>}
                                                            closeOnDocumentClick
                                                            closeOnEscape
                                                            modal
                                                            position="center center"
                                                        >
                                                            {close => (<UploadJustification
                                                                close={close}
                                                                exam={exam}
                                                                onSuccess={(_exam) => examChange(_exam)}
                                                                onError={(_exam) => {
                                                                }}
                                                            />)}
                                                        </Popup>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="no-data-message">
                                <Icon name="info circle" />
                                لا توجد امتحانات متاحة حالياً
                            </div>
                        )}

                        <div className="reglement-area">
                            <h4><Icon name="info circle" /> ملاحظات مهمة:</h4>
                            <ol>
                                <li>يمكنك من خلال المنصة تبرير فقط الوحدات التي قمت بالتغيب بها.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
