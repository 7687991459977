import React, {useEffect, useState} from "react";
import "../styles/navigation.css";
import "../styles/layout.css";
import { NavLink } from "react-router-dom";
import cloud from "../cloud.svg";
import { FaUser, FaBook, FaCalendarAlt, FaClipboardList, FaGraduationCap, FaFileAlt, FaListAlt, FaFileContract } from 'react-icons/fa';

export default function NavMenu(props) {
  // const [toggle, setToggle] = useState(true);
  var toggle = props.toggle;
  var setToggle = props.setToggle;

  const email = props.email;

  const [rosetteRegistered, setRosetteRegistered] = useState(false)

  useEffect(() => {// Replace with the actual user ID

    function checkRosettaStoneRegistration(roseta) {
      fetch(`https://ego.rosettastone.com/api/users/${roseta}/registration`)
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              setRosetteRegistered(false)
            }
          })
          .then(data => {
            if(data && data.email)
              setRosetteRegistered(false)
            else
              setRosetteRegistered(true)
            console.log('Response JSON:', data); // Log the response JSON
            // Further processing of data if needed
          })
          .catch(error => {
            setRosetteRegistered(false)
            console.error('Error:', error);
          });
    }

    if(email && email.roseta) {
      //checkRosettaStoneRegistration(email.roseta);
	  setRosetteRegistered(true)
    } else {
      setRosetteRegistered(true)
    }


    // Cleanup function
    return () => {
      // Perform cleanup if necessary
    };
  }, []);

  return (
    <div className="navContainer">
      {/* <button className="navButton" onClick={() => setToggle(!toggle)}>
        <svg height="30px" width="30px" viewBox="0 0 20 20">
          <path
            fill="#fff"
            d="M16.803,18.615h-4.535c-1,0-1.814-0.812-1.814-1.812v-4.535c0-1.002,0.814-1.814,1.814-1.814h4.535c1.001,0,1.813,0.812,1.813,1.814v4.535C18.616,17.803,17.804,18.615,16.803,18.615zM17.71,12.268c0-0.502-0.405-0.906-0.907-0.906h-4.535c-0.501,0-0.906,0.404-0.906,0.906v4.535c0,0.502,0.405,0.906,0.906,0.906h4.535c0.502,0,0.907-0.404,0.907-0.906V12.268z M16.803,9.546h-4.535c-1,0-1.814-0.812-1.814-1.814V3.198c0-1.002,0.814-1.814,1.814-1.814h4.535c1.001,0,1.813,0.812,1.813,1.814v4.534C18.616,8.734,17.804,9.546,16.803,9.546zM17.71,3.198c0-0.501-0.405-0.907-0.907-0.907h-4.535c-0.501,0-0.906,0.406-0.906,0.907v4.534c0,0.501,0.405,0.908,0.906,0.908h4.535c0.502,0,0.907-0.406,0.907-0.908V3.198z M7.733,18.615H3.198c-1.002,0-1.814-0.812-1.814-1.812v-4.535c0-1.002,0.812-1.814,1.814-1.814h4.535c1.002,0,1.814,0.812,1.814,1.814v4.535C9.547,17.803,8.735,18.615,7.733,18.615zM8.64,12.268c0-0.502-0.406-0.906-0.907-0.906H3.198c-0.501,0-0.907,0.404-0.907,0.906v4.535c0,0.502,0.406,0.906,0.907,0.906h4.535c0.501,0,0.907-0.404,0.907-0.906V12.268z M7.733,9.546H3.198c-1.002,0-1.814-0.812-1.814-1.814V3.198c0-1.002,0.812-1.814,1.814-1.814h4.535c1.002,0,1.814,0.812,1.814,1.814v4.534C9.547,8.734,8.735,9.546,7.733,9.546z M8.64,3.198c0-0.501-0.406-0.907-0.907-0.907H3.198c-0.501,0-0.907,0.406-0.907,0.907v4.534c0,0.501,0.406,0.908,0.907,0.908h4.535c0.501,0,0.907-0.406,0.907-0.908V3.198z"
          ></path>
        </svg>
      </button> */}

      <div className={toggle ? "navMenu" : "navMenu opened"}>
      <div className="nav-logo">
        <img src={cloud} className="image" />
        <div className="title">portail d'étudiant(e)</div>
      </div>
        { rosetteRegistered && (
            <>
              <NavLink
                  to="/profile"
                  activeClassName="active"
                  className="navMenuItem"
                  onClick={() => setToggle(!toggle)}
              >
                <div className="svg-icon">
                  <FaUser size={20} />
                </div>
                <div className="navMenuLink">profile</div>
              </NavLink>

              <NavLink
                  to="/subjects"
                  activeClassName="active"
                  className="navMenuItem"
                  disabled={!rosetteRegistered}
                  onClick={() => setToggle(!toggle)}
              >
                <div className="svg-icon">
                  <FaBook size={20} />
                </div>
                <div className="navMenuLink">modules</div>
              </NavLink>

              <NavLink
                  to="/abs_justification"
                  activeClassName="active"
                  className="navMenuItem"
                  onClick={() => setToggle(!toggle)}
              >
                <div className="svg-icon">
                  <FaClipboardList size={20} />
                </div>
                <div className="navMenuLink">Justification ABS</div>
              </NavLink>

              <NavLink
                  to="/timetable"
                  activeClassName="active"
                  className="navMenuItem"
                  onClick={() => setToggle(!toggle)}
              >
                <div className="svg-icon">
                  <FaCalendarAlt size={20} />
                </div>
                <div className="navMenuLink">Emplois du temps</div>
              </NavLink>

              <NavLink
                  to="/grades"
                  activeClassName="active"
                  className="navMenuItem"
                  onClick={() => setToggle(!toggle)}
              >
                <div className="svg-icon">
                  <FaGraduationCap size={20} />
                </div>
                <div className="navMenuLink">résultats</div>
              </NavLink>

              <NavLink
                  to="/demandes"
                  activeClassName="active"
                  className="navMenuItem"
                  onClick={() => setToggle(!toggle)}
              >
                <div className="svg-icon">
                  <FaFileAlt size={20} />
                </div>
                <div className="navMenuLink">Mes Demandes</div>
              </NavLink>

              <NavLink
                  to="/result-current"
                  activeClassName="active"
                  className="navMenuItem"
                  onClick={() => setToggle(!toggle)}
              >
                <div className="svg-icon">
                  <FaListAlt size={20} />
                </div>
                <div className="navMenuLink">Current Results</div>
              </NavLink>

              <NavLink
                  to="/convocation"
                  activeClassName="active"
                  className="navMenuItem"
                  onClick={() => setToggle(!toggle)}
              >
                <div className="svg-icon">
                  <FaFileContract size={20} />
                </div>
                <div className="navMenuLink">Convocation</div>
              </NavLink>
            </>
        )}

        {
          !rosetteRegistered && (
                <div style={
                  {
                    color: "#FFF",
                    display: "flex",
                    alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                  textAlign: "center",
                  fontSize: "18px",
                  }
                }>Merci d'activer votre compte Rosetta Stone</div>
            )
        }
      </div>
    </div>
  );
}
