import React, {useState} from "react";
import styles from "../styles/student.module.css";
import "../styles/layout.css";
import UploadPictureFormik from "./user/upload-picture";
import '@fontsource/cairo';
import '@fontsource/tajawal';
import '@fontsource/almarai';

export default function Profile(props) {
    const {profile, email} = props;
    const [showPass, setShowPass] = useState(false);
    const [language, setLanguage] = useState('ar');
    const [showCopySuccess, setShowCopySuccess] = useState(false);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setShowCopySuccess(true);
        setTimeout(() => setShowCopySuccess(false), 2000);
    };

    const translations = {
        ar: {
            profile: 'الملف الشخصي',
            personalInfo: 'المعلومات الشخصية',
            accountInfo: 'معلومات الحساب',
            regNumber: 'رقم التسجيل',
            nationalId: 'رقم البطاقة الوطنية',
            nameAr: 'الاسم الكامل بالعربية',
            nameFr: 'الاسم الكامل بالفرنسية',
            birthDate: 'تاريخ الميلاد',
            birthPlace: 'مكان الميلاد',
            email: 'البريد الإلكتروني',
            password: 'كلمة المرور المؤقتة',
            show: 'إظهار',
            hide: 'إخفاء',
            copy: 'نسخ',
            copied: 'تم النسخ!',
            activate: 'لتفعيل حسابك، يرجى زيارة:'
        },
        fr: {
            profile: 'Profil d\'étudiant',
            personalInfo: 'Informations personnelles',
            accountInfo: 'Informations du compte',
            regNumber: 'N° d\'inscription',
            nationalId: 'CIN',
            nameAr: 'Nom complet en arabe',
            nameFr: 'Nom complet en français',
            birthDate: 'Date de naissance',
            birthPlace: 'Lieu de naissance',
            email: 'Email',
            password: 'Mot de passe temporaire',
            show: 'Afficher',
            hide: 'Masquer',
            copy: 'Copier',
            copied: 'Copié!',
            activate: 'Pour activer votre compte, visitez:'
        }
    };

    const t = translations[language];

    return (
        <div className={styles.container}>
            <div className={styles.sectionLanguage}>
                <button 
                    className={`${styles.languageButton} ${language === 'ar' ? styles.active : ''}`}
                    onClick={() => setLanguage('ar')}
                >
                    العربية
                </button>
                <button 
                    className={`${styles.languageButton} ${language === 'fr' ? styles.active : ''}`}
                    onClick={() => setLanguage('fr')}
                >
                    Français
                </button>
            </div>

            <h1 className="head1">{t.profile}</h1>

            <div className={styles.cardSection}>
                <h2 className={styles.sectionTitle}>{t.personalInfo}</h2>
                <div className="row">
                    <div className="col-md-3">
                        <UploadPictureFormik 
                            apo={profile.id} 
                            dn={profile.et_birthday} 
                            picture={profile.picture || null}
                        />
                    </div>
                    <div className="col-md-9">
                        <div className={styles.profileGrid}>
                            <div className={styles.profileTag}>
                                <span>{t.regNumber}</span>
                                <span>{profile.id}</span>
                            </div>
                            <div className={styles.profileTag}>
                                <span>{t.nationalId}</span>
                                <span>{profile.et_cin}</span>
                            </div>
                            <div className={styles.profileTag}>
                                <span>{t.nameAr}</span>
                                <span>{`${profile.et_f_name_ar} ${profile.et_l_name_ar}`}</span>
                            </div>
                            <div className={styles.profileTag}>
                                <span>{t.nameFr}</span>
                                <span>{`${profile.et_f_name_fr} ${profile.et_l_name_fr}`}</span>
                            </div>
                            <div className={styles.profileTag}>
                                <span>{t.birthDate}</span>
                                <span>{profile.et_birthday ? profile.et_birthday.substring(0, 10) : ""}</span>
                            </div>
                            <div className={styles.profileTag}>
                                <span>{t.birthPlace}</span>
                                <span>{profile.et_birthday_city}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {email && (
                <div className={styles.cardSection}>
                    <h2 className={styles.sectionTitle}>{t.accountInfo}</h2>
                    
                    <div className={styles.emailSection}>
                        <h3 className={styles.emailTitle}>{t.email}</h3>
                        <div className={styles.profileTag}>
                            <span>{t.email}</span>
                            <div className={styles.passwordField}>
                                <span className={styles.passwordValue}>{email.email}</span>
                                <div className={styles.passwordActions}>
                                    <button 
                                        className={`${styles.actionButton} ${styles.copyButton}`}
                                        onClick={() => copyToClipboard(email.email)}
                                    >
                                        <svg viewBox="0 0 24 24" fill="currentColor">
                                            <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
                                        </svg>
                                        {t.copy}
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div className={styles.profileTag}>
                            <span>{t.password}</span>
                            <div className={styles.passwordField}>
                                <span className={styles.passwordValue}>
                                    {showPass ? email.password : "••••••••••••"}
                                </span>
                                <div className={styles.passwordActions}>
                                    <button 
                                        className={`${styles.actionButton} ${styles.togglePassword}`}
                                        onClick={() => setShowPass(!showPass)}
                                    >
                                        {showPass ? (
                                            <svg viewBox="0 0 24 24" fill="currentColor">
                                                <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"/>
                                            </svg>
                                        ) : (
                                            <svg viewBox="0 0 24 24" fill="currentColor">
                                                <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
                                            </svg>
                                        )}
                                        {showPass ? t.hide : t.show}
                                    </button>
                                    <button 
                                        className={styles.actionButton}
                                        onClick={() => copyToClipboard(email.password)}
                                    >
                                        <svg viewBox="0 0 24 24" fill="currentColor">
                                            <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
                                        </svg>
                                        {t.copy}
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div className={styles.activationInfo}>
                            <p>{t.activate}</p>
                            <a href="https://www.office.com/" target="_blank" rel="noopener noreferrer">
                                www.office.com
                            </a>
                        </div>
                    </div>

                    {email.moodle && (
                        <div className={styles.emailSection}>
                            <h3 className={styles.emailTitle}>منصة Moodle التعليمية</h3>
                            <div className={styles.profileTag}>
                                <span>اسم المستخدم</span>
                                <span>{email.moodle}</span>
                            </div>
                            <div className={styles.activationInfo}>
                                <p>للوصول إلى المنصة التعليمية:</p>
                                <a href="http://ent.usms.ac.ma/moodle" target="_blank" rel="noopener noreferrer">
                                    ent.usms.ac.ma/moodle
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {showCopySuccess && (
                <div className={styles.copySuccess}>
                    {t.copied}
                </div>
            )}
        </div>
    );
}
